.editor {
    counter-reset: line;
    border: 1px solid #ced4da;
    border-radius: 8px;
}

.editor #codeArea {
    outline: none;
    padding-left: 60px !important;
}

.editor pre {
    padding-left: 60px !important;
}
.ql-snow .ql-tooltip{
    position: revert;
}

.editor .editorLineNumber {
    position: absolute;
    left: 0px;
    color: #cccccc;
    text-align: right;
    width: 40px;
    font-weight: 100;
}

[data-color-mode*="dark"],
[data-color-mode*="dark"] body {
    --color-canvas-subtle: #161b22;
}

[data-color-mode*="light"],
[data-color-mode*="light"] body {
    --color-canvas-subtle: #f6f8fa;
}