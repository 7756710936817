::-webkit-scrollbar {
    width: 4px;               /* width of vertical scrollbar */
    border: 1px solid rgb(150, 150, 150);
    border-radius: 10px;
    max-height: 8px;
  }

  /* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px #444; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #58B774; 
    border-radius: 10px;
  }