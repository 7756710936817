#simulationSec [class^="makeStyles-content-"],
#simulationSec [class^="makeStyles-body-"],
#simulationSec [class^="makeStyles-gridContainer-"]{
    width: 100%;
}

#simulationSec [class^="makeStyles-keycellanimation-"]{
  width: 100% !important;
}

#image-container, #image-container [class^="makeStyles-content-"] {
    width: 1022px;
}

#simulationSec [class^="makeStyles-body-"] ,
.containerHighLight + div > div:has(> button){
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .simulationScreen{
    width: 100% !important;
  }
}