@media screen and (min-width: 600px) {
    #clickFunctiontoggle{
        display: none;
    }
    button[aria-label*="Navigate to subscribe page"]+ div {
        display: block !important;
    }
}
@media screen and (max-width: 600px) {
    .actived .navMenu{
        display: none;
    }
    #clickFunctiontoggle{
        display: block;
    }
}
@media screen and (max-width: 1300px) and (min-width: 1200px){
.userProfile {
    margin-left:  20 !important;
}
}
@media screen and (max-width: 1200px)and (min-width: 1115px){
    .userProfile {
        margin-left:  96px !important;
    }
}
@media screen and (max-width: 1115px)and (min-width: 900px){
    .userProfile {
        margin-left:  40px !important;
    }
}
@media screen and (max-width: 1200px) and (min-width: 900px){
    .searchField {
        min-width: 300px;
    }
}

@media screen and (max-width: 1069px){
    .searchField{
        width: auto !important;
    }
    .leftSectionIs{
        flex: 3 !important;
    }
    .userProfile{
        margin-left: 0;
        float: inline-end;
    }
    .logoIs{
        flex: 1;
    }
    
}


/* @media screen and (max-width: 1068px) and (min-width: ) {
    
} */
