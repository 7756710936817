@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

::-webkit-scrollbar {
    width: 12px;
}
 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.carousel .slide img {
  height: 250px;
}

@media screen and (max-width: 900px) and (min-width: 600px) {
  .swiper-wrapper {
    width: 250px !important;
  }
  .mySwiper .swiper-wrapper{
    height: '390px';
    padding-top: '10px';
  }
  } 

  .MuiDropzoneArea-root{
    min-height: 110px !important;
  }

  .MuiDropzoneArea-text{
    margin: 0 !important;
    font-size: 0.95rem !important;
    padding-top: 1rem;
  }

  .MuiDropzoneArea-icon{
    color: rgb(37 72 233 / 87%) !important;
    width: 40px !important;
  }

  .rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    background: #f2f2f287;
    min-height: 100px;
    padding: 0 8px;
    border-radius: 4px;
    border: 1px solid #dce0e4;
}

  
.mejs-container-fullscreen {
  background-color: #fff !important;
}

.mejs-container-fullscreen .mejs-mediaelement,
.mejs-container-fullscreen video {
  height: 530px !important;
  top: 500px !important;
}

.mejs-container.mejs-container-fullscreen .mejs-controls {
  bottom: auto !important;
  top: 1000px !important;
  /* video top + video height - controls height (30px) */
}

.swal-button--confirm {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #FE7000;
  font-size: 12px;
  /* border: 1px solid #3e549a; */
  /* text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3); */
}

.swal-button--cancel {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #acb4b1;
  font-size: 12px;
  color: rgb(81, 79, 79);
  /* border: 1px solid #3e549a; */
  /* text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3); */
}


.wg-default .country-selector {
  bottom: 0;
  position: fixed;
  right: auto !important;
  left: 20px;
}

html {
  scroll-behavior: smooth;
}

.tox-notifications-container{
  display: none;
}


.fontdetails{
  font-family:'Poppins',
}
/* .element.style{
  margin-right: "-90px!important"
} */