.rh5v-Overlay_inner {
  background-color: #fe9700ed !important;
  width: 50px;
  height: 41px;
}
.rh5v-Volume_icon {
  padding: 0px;
}
.rh5v-Fullscreen_icon {
  padding: 0px;
}

.css-1ffk4d9-MuiButtonBase-root-MuiButton-root {
  display: none;
}
.rh5v-Seek_fill {
  background: #fe9700ed;
}

.rh5v-PlayPause_icon {
  padding: 0px;
}

.rh5v-Time_current {
  margin-right: 5px;
  /* color: #fe9700ed; */
}
.rh5v-Time_duration {
  margin-left: 5px;
  /* color: #fe9700ed; */
}

.rh5v-Volume_fill {
  background-color: #fe9700ed;
}

.rh5v-Fullscreen_button {
  /* background-color:  #fe9700ed; */
}
.rh5v-DefaultPlayer_controls {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  /* height: 34px; */
  display: flex;
  opacity: 0;
  border-radius: 50px !important;
  /* transition: opacity 0.2s; */
  /* padding: 0px 63px 18px 61px; */
  padding: 0px 22px;
}

.rh5v-DefaultPlayer_component {
  position: relative;
  font-family: Helvetica;
  font-size: 11px;
  background-color: #000;
  border-radius: 15px;
}
.rh5v-DefaultPlayer_video {
  width: 100%;
  height: 100%;
  padding: 3px 4px 3px 6px;
  border-radius: 10px;
}
