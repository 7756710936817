.css-1bdadyq:hover {
    background-color: #4648b8 !important;
    border-radius: 8px;
}

.css-ra3y5v:hover {
    background-color: #4648b8 !important;
    border-radius: 8px;
}

.css-1bdadyq {
    padding-right: 8px !important;
    padding-left: 8px !important;
    margin: 0 8px;
}

.css-ra3y5v {
    margin: 0 8px;

}

.css-1l5fxv3 {
    background-color: #4648b8 !important;
}

.css-154rkrx {
    display: flex !important;
    flex-direction: column !important;
}

.css-fayd5x-MenuButtonRef:hover {
    background-color: #002497 !important;
}

.ps-menu-button:hover {
    background-color: #002497 !important;
}

.css-1l5fxv3 {
    background: #000e3b !important;
}

a.ps-menu-button.ps-menu-button.ps-active.css-1omujol-MenuButtonRef {
    background: #00b673 !important;
}

a.css-t5fjmg-MenuButtonRef.ps-active.ps-menu-button {
    background: #00b673 !important;
}

.css-z5rm24 {
    background-color: #000e3b !important;
}

a.ps-menu-button.ps-active {
    background: #00b673 !important;
}
.ps-open > ul li a{
    background: #f3f3f3 !important;
    color: rgb(28 62 98) !important;
}
.ps-open > ul li a:hover{
    color: rgb(225 225 225) !important;
}