.rgb-animate {
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-name: rgb_anim;
}

.contentrgb {
    width: 500px;
    height: 92px;
}

@keyframes rgb_anim {
    from {
        filter: hue-rotate(0deg);
    }

    50% {
        filter: hue-rotate(360deg);
    }

    to {
        filter: hue-rotate(0deg);
    }
}

.contentrgb {
    background: #ec6c6c;
    /* background: -webkit-linear-gradient(to right bottom, #2657eb, #de6161); */
    background: linear-gradient(to right bottom, #5982ff, #f3baba);
}

@media screen and (max-width: 1330px) {
    .generalSection .generalHeading{
        margin-left:  9.5% !important ;
    }
    
}

@media screen and (max-width: 1330px) and (min-width: 769px){
    .generalSection .pannelcontent{
        min-width: calc(100vw - 350px);
        margin-right: 20px;
    }
    .properAlign{
        margin-right: 0;
    } 
    .generalSection > div:first-child{
        min-width: 210px;
        /* width: 210px; */
    } 
}

@media screen and (max-width: 1330px) and (min-width: 1258px){
    .generalSection .pannelcontent {
        min-width: calc(100vw - 360px);
    }
    }
@media screen and (min-width: 900px) {
    .contentrgb {
        width: auto !important;
    }
}
@media screen and (max-width: 768px) {
   .generalSection {
    padding-right: 0 !important;
    padding-left: 0 !important;
   }
  .sectionOne{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }
  .sectionTwo{
    display: none;
  }
  .sectionOne > div{
    width: 120px;
  }
  .generalSection .generalHeading{
    margin-top: 5px;
  }

  .GridParent, .generalSection > div:first-child{
    flex-basis: 100% !important;
  }
  .generalSection .pannelcontent{
    min-width: calc(93vw);
    margin: 0px;
    padding: 0px !important;
}
.generalSection > div:first-child{
    max-width: 100%;
}
.boardClass{
    margin-top: 15px !important;
}
.Assessmentpannel{
    min-height: fit-content !important;
    width: 80% !important;
    margin: auto !important;
}
.contentrgb {
    width: 80%;
}
.sectionParent{
    width: 80% !important;
    border-radius: 8px;
    margin: auto !important;
    display: flex;
    padding: 8px !important;
}
.TimeLeft{
    margin-right: 10px !important;
}
nav[aria-label="breadcrumb"]{
    padding-left: 15px !important;
}
}

@media screen and (max-width: 1330px) and (min-width: 769px) {
    .ScoreBoard{
        margin-left: 200px !important;
    }
}